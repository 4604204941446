export const checkUserForwardedMandrillEmailReducer = (state = null, action) => {

    if (action.type === 'MANDRILL_VERIFICATION_FORWARDED_RESPONSE_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'MANDRILL_VERIFICATION_FORWARDED_RESPONSE_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'RESET_MANDRILL_VERIFICATION_EMAIL_CHECK_STORE') {
        return null;
    }
    return state;
};
