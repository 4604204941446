import React, {Component} from 'react';
import {Field} from 'redux-form';
import '../sign-up.css';
import {FormField} from "../../../core/util/FormField";

class CompanyDetails extends Component {

    render() {
        return (
            <div className="card">
                <div id="headingTwo" className="card-header">
                    <h2 className="mb-0">
                        <button type="button"
                                onClick={() => this.props.onAccordionClick('company_details')}
                                aria-expanded="false"
                                className="btn btn-link  collapsed   ">
                            Your Company
                        </button>
                    </h2>
                </div>

                <div id="collapseTwo" aria-labelledby="headingTwo"
                     data-parent="#accordionExample"
                     className={this.props.expandOnSubmit === true ? "accordion-expand" : 'accordion-collapse'}>
                    <div className="card-body">
                        <div className="form-group mt-3 mb-5"><label htmlFor="company_name">What is your legal
                            company name? <span
                                className="text-danger"> *</span></label>
                            <Field name="company_name" component={FormField.renderInputField} type="text"
                                   className="form-control"
                                   onChange={this.props.persistFormData}
                                   label="Company name"/>
                        </div>
                        <div className="form-group mb-5"><label htmlFor="company_address">What is your company
                            address? <span className="text-danger"> *</span></label>
                            <Field name="company_address" component={FormField.renderInputField} type="text"
                                   className="form-control"
                                   onChange={this.props.persistFormData}
                                   label="Company address"/>
                        </div>

                        <button type="button" onClick={() => this.props.onAccordionClick('program_details')}
                                className="float-right btn btn-success  mb-5">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyDetails;
