import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";
import Logger from "../../core/util/Logger";
import {sendNewMemberInvitationAction} from "../../actions/admin/send-new-member-invitation";
import "./inviteNewMember.css"

const initialState = {
    start_sending_member_invitation: false,
    show_invitation_form: true,
    message_class: undefined,
    message_text: undefined
};


const defaultEmailHeader = 'Welcome to EasyMacroPlanner';

const defaultEmailBody = 'Hey  {user.name},\n' +
    '\n' +
    'Congratulations, it is GREAT to have you onboard with EasyMacroPlanner!!!\n' +
    '\n' +
    'I am your account manager and will be your first point of contact for your new app.\n' +
    '\n' +
    'We are super excited to get started on your app. Thank you for accepting the proposal. The next steps are:\n' +
    '\n' +
    'Complete the signup process - {invitation_url}\n' +
    'Join the MacroActivists Facebook Group - https://www.facebook.com/groups/macroactive/\n' +
    'Pay the invoice - Accounts will email this to you (Please email me if you do not receive it within 48 hours).\n' +
    'Hear from you soon.\n' +
    '\n' +
    'Warm Regards,\n' +
    '{admin.name} \n'+
    '{admin.phone}';

class InviteNewMember extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = initialState;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.member_invitation != null && this.state.start_sending_member_invitation === true) {
            Logger.info("data received");
            this.setState({start_sending_member_invitation: false});

            if (this.props.member_invitation.success === true) {
                this.setState({
                    show_invitation_form: false,
                    message_class: 'alert-success',
                    message_text: this.props.member_invitation.data.message
                })
            } else {
                this.setState({
                    show_invitation_form: true,
                    message_class: 'alert-danger',
                    message_text: this.props.member_invitation.data.message
                })
            }
        }
    }

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'invite_new_member_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Invite new member
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">

                                <div
                                    style={this.state.message_class !== undefined ? {display: 'block'} : {display: 'none'}}
                                    className={"alert " + this.state.message_class} role="alert">
                                    {this.state.message_text !== undefined && this.state.message_text}
                                </div>


                                <div>
                                    <div
                                        style={this.state.show_invitation_form === true ? {display: 'block'} : {display: 'none'}}
                                        className="form-inline">
                                        <Formik
                                            ref={this.formik}
                                            initialValues={{
                                                name: '',
                                                email: '',
                                                subject: defaultEmailHeader,
                                                message: defaultEmailBody,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string()
                                                    .required("Name is required"),
                                                email: Yup.string().email("Invalid email")
                                                    .required('Email is required'),
                                                subject: Yup.string()
                                                    .required("Subject is required"),
                                                message: Yup.string()
                                                    .required("Message is required")
                                            })}
                                            onSubmit={fields => {
                                                Logger.info("handle submission");
                                                this.handleInviteMember(fields);
                                            }}
                                            render={({errors, status, touched}) => (
                                                <div className={'invite-popup-wrap'}>
                                                    <Form>
                                                        <div className={"row mb-2"}>
                                                            <div className={"col-md-2"}>
                                                                <div className="form-group">
                                                                    <label>Name</label>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-10"}>
                                                                <Field name="name" type="text"
                                                                       style={{paddingRight: 'none !important'}}
                                                                       className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="name" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </div>
                                                        <div className={"row mb-2"}>
                                                            <div className={"col-md-2"}>
                                                                <div className="form-group">
                                                                    <label>Email</label>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-10"}>
                                                                <Field name="email" type="text"
                                                                       style={{paddingRight: 'none !important'}}
                                                                       className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="email" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </div>
                                                        <div className={"row mb-2"}>
                                                            <div className={"col-md-2"}>
                                                                <div className="form-group">
                                                                    <label>Subject</label>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-10"}>
                                                                <Field name="subject" type="text"
                                                                       style={{paddingRight: 'none !important'}}
                                                                       className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="subject" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col-md-2"}>
                                                                <div className="form-group">
                                                                    <label>Message</label>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-10"}>

                                                                <Field name="message" component="textarea"
                                                                       style={{paddingRight: 'none !important', width:'100%',height:'150px',fontSize:'12px'}}
                                                                       className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}/>

                                                                <ErrorMessage name="message" component="div"
                                                                              className="invalid-feedback"/>

                                                            </div>
                                                        </div>

                                                        <button type={"submit"}
                                                                className="btn btn-primary mb-2 mt-2 float-right">
                                                            Send
                                                        </button>
                                                    </Form>
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="modal-body jrf-popup"
                                         style={this.state.start_sending_member_invitation === true ? {display: 'block'} : {display: 'none'}}>
                                        <p className="text-center">
                                            Please wait... invitation is being sent
                                        </p>
                                        <img src="../../../images/spinner.gif" className="loder"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.props.resetReduxStore("RESET_NEW_MEMBER_INVITATION");
        this.setState(initialState);
        this.formik.current.resetForm();

        let payload = {
            id: "invite_new_member_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    handleInviteMember(data) {
        Logger.info("send new member invitation", data);
        this.props.resetReduxStore("RESET_NEW_MEMBER_INVITATION");
        this.setState({start_sending_member_invitation: true, show_invitation_form: false});
        let token = this.props.user.data.auth.token;
        this.props.sendMemberInvitation(token, data);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        member_invitation: state.member_invitation
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    sendMemberInvitation: sendNewMemberInvitationAction
};


export default withRouter(connect(mapStateToProps, actions)(InviteNewMember));
