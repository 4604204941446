import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {resendMandrillVerificationEmailByUser} from "../../../actions/user/user-resend-mandrill-domain-verification-email-action";

class RetrieveLatestDnsUpdate extends Component {

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'latest_dns_retrieval_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog">

                        <div style={this.props.dns.status === "pending" ? {display: 'block'} : {display: 'none'}}
                             className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Checking Dns Details
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">
                                <div className="modal-body jrf-popup">
                                    <p className="text-center">
                                        Please wait... we are checking your DNS details
                                    </p>
                                    <img src="../../../images/spinner.gif" className="loder"/>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>


                        <div
                            style={this.props.dns.data.progress.verification_status === "COMPLETED" ? {display: 'block'} : {display: 'none'}}
                            className="modal-content">

                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Completed
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">
                                <div className="modal-body jrf-popup">
                                    <p className="text-center">
                                        Congratulations. Your set up is completed
                                    </p>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>


                        <div
                            style={this.props.dns.data.progress.verification_status === "PENDING" ? {display: 'block'} : {display: 'none'}}
                            className="modal-content recode-not-uploaded">

                            <div className="modal-header text-center">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    <i className="fas fa-exclamation-triangle"></i>All records are not updated yet.
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">
                                <div className="modal-body jrf-popup">
                                    <p className="text-center">
                                        Sometimes it can take up to 48 hours for changes to take effect.
                                    </p>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("latest dns retrieval popup is closed");

        let popupDetails = {
            id: "latest_dns_retrieval_popup",
            shouldDisplay: false
        };
        this.props.popUpHandler(popupDetails);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        dns: state.dns
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    popUpHandler: popupDisplayHandlerAction,
    resendMandrillVerificationEmail: resendMandrillVerificationEmailByUser
};


export default withRouter(connect(mapStateToProps, actions)(RetrieveLatestDnsUpdate));