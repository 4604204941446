import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../../actions/popup-display-action";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {adminResendMandrillDomainVerificationEmailAction,} from "../../../../actions/admin/admin-resend-mandrill-domain-verification-email-action";
import {resetReduxStoreAction} from "../../../../actions/reset-redux-store-action";

const initialState = {
    start_sending_email_verification: false,
    message_type: '',
    message: '',
    show_mandrill_verification_form: true
};

let showForm = false;
let setUpDomains = false;
let isMacroActiveOwnedDomain = false;

class AdminMandrillVerificationEmailPopup extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = initialState;
    }


    componentDidUpdate() {
        //success message is received
        if (this.props.mandrill_verification !== null && this.props.mandrill_verification.success === true && this.state.start_sending_email_verification === true) {
            Logger.info("handling the success message");
            this.setState({
                start_sending_email_verification: false,
                message_type: 'success',
                message: this.props.mandrill_verification.data.message,
                show_mandrill_verification_form: false
            });
        }

        //error message is received
        if (this.props.mandrill_verification !== null && this.props.mandrill_verification.success === false && this.state.start_sending_email_verification === true) {
            Logger.info("hiding the loading view on error message");
            // this.setState({start_sending_email_verification: false});
            this.setState({
                start_sending_email_verification: false,
                message_type: 'error',
                message: this.props.mandrill_verification.data.message
            });
        }
    }

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'admin_mandrill_verification_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Mandrill Verification
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">

                                <div
                                    style={this.state.message !== '' ? {display: 'block'} : {display: 'none'}}
                                    className={this.state.message_type !== '' && this.state.message_type === 'success' ? "alert alert-success" : "alert alert-danger"}
                                    role="alert">
                                    {
                                        this.state.message
                                    }
                                </div>

                                {
                                    setUpDomains = (this.props.member_profile != null
                                        && this.props.member_profile.data.domain_details !== undefined)
                                }

                                {
                                    isMacroActiveOwnedDomain = (this.props.member_profile != null &&
                                        this.props.member_profile.data.domain_details !== undefined &&
                                        this.props.member_profile.data.domain_details.domain_type === 'new_domain')
                                }

                                {
                                    showForm = (this.state.show_mandrill_verification_form === true && this.state.start_sending_email_verification === false && setUpDomains && !isMacroActiveOwnedDomain)
                                }

                                <div id={"mandrill-verification"}>
                                    <div className="form-inline"
                                         style={showForm ? {display: 'block'} : {display: 'none'}}>
                                        <Formik
                                            ref={this.formik}
                                            initialValues={{
                                                mailbox_id: '',
                                            }}
                                            validationSchema={Yup.object().shape({
                                                mailbox_id: Yup.string()
                                                    .required('Email id is required'),
                                            })}
                                            onSubmit={fields => {
                                                Logger.info("handle submission");
                                                this.onSubmitHandler(fields);
                                            }}
                                            render={({errors, status, touched}) => (
                                                <div>
                                                    <Form>
                                                        <div className={"row"}>
                                                            <div className={"form-group col-md-6"}>
                                                                <Field name="mailbox_id" type="text"
                                                                       style={{paddingRight: 'none !important'}}
                                                                       className={'form-control mb-2' + (errors.mailbox_id && touched.mailbox_id ? ' is-invalid' : '')}/>
                                                                <ErrorMessage name="mailbox_id" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                            <div className={"form-group col-md-6"}>
                                                                <p className="form-control-plaintext">
                                                                    @{this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.props.member_profile.data.domain_details.domain}
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <button className="btn btn-primary mb-2">
                                                            Send
                                                        </button>
                                                    </Form>
                                                </div>
                                            )}
                                        />

                                    </div>


                                    <div className="modal-body jrf-popup"
                                         style={this.state.start_sending_email_verification === true ? {display: 'block'} : {display: 'none'}}>
                                        <p className="text-center">
                                            Please wait... email is being sent
                                        </p>
                                        <img src="../../../images/spinner.gif" className="loder"/>
                                    </div>
                                </div>


                                <div style={setUpDomains === false ? {display: 'block'} : {display: 'none'}}
                                     className={"alert alert-danger"}>
                                    User has not set the domain details
                                </div>


                                <div
                                    style={isMacroActiveOwnedDomain === true && this.state.start_sending_email_verification === false && this.state.show_mandrill_verification_form === true ? {display: 'block'} : {display: 'none'}}>
                                    <span>This domain is owned by EasyMacroPlanner.
                                        Click Send Verification Email below and we'll
                                        initiate an email to verifications@macroactive.com
                                    </span>
                                    <button className={"btn btn-primary"} onClick={this.sendMandrillVerificationOwnedDomain.bind(this)}>Send
                                        Verification Email
                                    </button>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("mandrill verification popup is closed");
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");
        this.props.resetReduxStore("RESET_MANDRILL_VERIFICATION_STORE");
        this.formik.current.resetForm();

        this.setState(initialState);

        let payload = {
            id: "admin_mandrill_verification_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }

    onSubmitHandler = (data) => {
        Logger.info("on submit send mandrill verification", data);
        this.props.resendMandrillVerificationEmail(this.props.user.data.auth.token, this.props.member_profile.data.user_id, data.mailbox_id);
        this.setState({start_sending_email_verification: true, message: initialState.message});
    };

    sendMandrillVerificationOwnedDomain = () => {
        Logger.info("on submit send mandrill verification");
        this.props.resendMandrillVerificationEmail(this.props.user.data.auth.token, this.props.member_profile.data.user_id, "verify");
        this.setState({start_sending_email_verification: true, message: initialState.message});
    };
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        mandrill_verification: state.mandrill_verification,
        member_profile: state.member_profile
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resendMandrillVerificationEmail: adminResendMandrillDomainVerificationEmailAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(AdminMandrillVerificationEmailPopup));