import React, {Component} from 'react';

export default class DashboardPendingItem extends Component {

    render() {
        return (
            <a className="setup-selection-btn "
               onClick={() => this.props.onClickNavigation(this.props.data.path)}>
                <div className="setup-selection-text">
                    <span className="next"><i
                        className="fas fa-arrow-circle-right"></i></span>
                    {
                        this.props.data.name
                    }
                </div>
            </a>
        );
    }
}