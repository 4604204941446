import Logger from "../core/util/Logger";

export const userAuthenticationReducer = (state = {
    authenticated: false,
    data: {auth: {}, profile: {}}
}, action) => {

    if (action.type === 'USER_AUTHENTICATION_SUCCESS') {
        Logger.info("handle redux-store for user authentication success");
        return {
            authenticated: true,
            data: action.payload,
        };
    }
    else if (action.type === 'USER_AUTHENTICATION_FAILED' || action.type === 'USER_LOGOUT') {
        Logger.info("handle redux-store for user authentication failure / Logout");
        return {
            authenticated: false,
            data: action.payload,
        };
    }
    return state;
};
