import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const adminResendMandrillDomainVerificationEmailAction = (token, userId, mailBoxId) => async dispatch => {

    Logger.info("resending mandrill domain verification email for member [" + userId + "] to mailbox [" + mailBoxId + "]  with token [" + token + "]");

    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("mailbox_id", mailBoxId);

    let response = await RestApiService.post("/admin/mandrill/verification", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("mandrill verification email sent");
        dispatch({
            type: "RESEND_MANDRILL_VERIFICATION_SUCCESS",
            payload: {
                message: "Verification email has been sent successfully"
            }
        });
    }
    else {
        dispatch({
            type: "RESEND_MANDRILL_VERIFICATION_FAILED",
            payload: {
                message: "Error occurred while sending the email. Please try again shortly"
            }
        });
    }
};