import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getMemberDnsConfigurationDetailsAction = (token, userId, popupDetails) => async dispatch => {
    Logger.info("getting DNS details by token [" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("admin/members/dns_configurations/" + userId, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("DNS details received successfully");
        dispatch({
            type: "DNS_DETAILS_RECEIVED_SUCCESS",
            payload: response.data
        });

        // //display the related popup once the data is received
        // if (popupDetails != null) {
        //     dispatch({
        //         type: "POPUP_HANDLER",
        //         payload: popupDetails
        //     });
        // }
    } else {
        Logger.info("error in getting DNS details ", response.data);
        let error = {
            message: "An error occurred while retrieving the DNS details. Please refresh and try again shortly." +
                " If you receive the error continuously, please contact EasyMacroPlanner team!"
        };
        dispatch({type: "DNS_DETAILS_RECEIVED_FAILED", payload: {message: response.data.message}});

        // //display the related popup once the data is received
        // if (popupDetails != null) {
        //     dispatch({
        //         type: "POPUP_HANDLER",
        //         payload: popupDetails
        //     });
        // }
    }

    //display the relate popup
    if (popupDetails != null) {
        dispatch({
            type: "POPUP_HANDLER",
            payload: popupDetails
        });
    }

    //loader need to be hidden, if the displayLoader is set yo true
    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};