import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
// import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";
import {getMemberProfileDetailsByUserIdAction} from "../../../actions/admin/get-member-details-by-id-action";
import Logger from "../../../core/util/Logger";
import {getMemberDnsConfigurationDetailsAction} from "../../../actions/admin/get-dns-configuration-details-by-admin-action";

// import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";

class Member extends Component {

    render() {
        return (
            <tr>
                <th scope="row">{this.props.member.name}</th>
                <td>{this.props.member.email}</td>
                <td>{this.props.member.domain}</td>
                <td>{this.props.member.domain_type}</td>
                <td>
                    <span style={this.props.member.mandrill_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-success">
                        Completed
                    </span>
                    <span style={!this.props.member.mandrill_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-warning">
                        Pending
                    </span>
                </td>

                <td>
                    <span style={this.props.member.dns_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-success">Completed</span>
                    <span style={!this.props.member.dns_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-warning">
                        Pending
                    </span>
                </td>

                <td>
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div className="dropdown-menu">
                            <a className="dropdown-item"
                               onClick={() => this.onClickViewMemberSubmissionAction(this.props.member.id)}>
                                View Submission
                            </a>
                            <a className="dropdown-item"
                               onClick={() => this.onClickViewMemberDnsConfigurationAction(this.props.member.id)}>
                                Setup DNS
                            </a>
                            <a className="dropdown-item"
                               onClick={() => this.onClickVerifyMandrillAction(this.props.member.id)}>
                                Verify Mandrill
                            </a>
                            <a onClick={() => this.onClickArchiveMemberAction(this.props.member.id)}
                               className="dropdown-item">Archive</a>

                            <a onClick={() => this.onClickResetPasswordAction(this.props.member.id)}
                               className="dropdown-item">Reset Password</a>
                        </div>
                    </div>

                </td>
            </tr>
        );
    }

    onClickViewMemberSubmissionAction = (memberId) => {
        Logger.info("click on member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_details_popup",
            shouldDisplay: true
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickVerifyMandrillAction = (memberId) => {
        Logger.info("click on verify mandrill action for member id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "admin_mandrill_verification_popup",
            shouldDisplay: true
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };

    onClickViewMemberDnsConfigurationAction = (memberId) => {
        Logger.info("click on member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_dns_configuration_popup",
            shouldDisplay: true
        };
        this.props.getMemberDnsDetails(token, memberId, popupDetails);
    };


    onClickArchiveMemberAction = (memberId, name) => {
        Logger.info("click on archive member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "archive_member_confirmation_popup",
            shouldDisplay: true,
        };
        // this.props.handlePopupVisibility(popupDetails);
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickResetPasswordAction = (memberId) => {
        Logger.info("click on archive member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_password_reset_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        all_members: state.all_members
    };
};

const actions = {
    // getAllMembers: getAllRegisteredMembersAction,
    getMemberDetailsById: getMemberProfileDetailsByUserIdAction,
    getMemberDnsDetails: getMemberDnsConfigurationDetailsAction
    // handlePopupVisibility: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(Member));