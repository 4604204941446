import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {handlerLoaderVisibility} from "../../actions/handle-loader-visibility-action";
import BackToHome from "../user-dahsboard/BackToHome";
import './mandrill.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";


class MandrillHome extends Component {

    constructor() {
        super();
        this.state = {
            error_message: null,
            show_account_manager_details: false
        };
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className="container">
                    <div className="card content-box">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main className="py-4">
                            <div className="container text-center">
                                {/*<div className="content-box">*/}
                                    <div className="mandrill-wrap">
                                        <div className="first-section">
                                            <p className="fc-ternary">In order to send emails on your behalf,
                                                our service provider Mandrill wants to verify your domain ownership</p>
                                        </div>
                                        <div className="second-section">

                                            <a className="btn btn-primary"
                                               onClick={this.onClickSendVerificationEmail.bind(this)}>
                                                Send Verification Email
                                            </a>
                                        </div>
                                    </div>
                                {/*</div>*/}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    onClickSendVerificationEmail() {
        this.props.history.push("/mandrill/send-verification");
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const actions = {
    displayLoader: handlerLoaderVisibility,
};

export default withRouter(connect(mapStateToProps, actions)(MandrillHome));
