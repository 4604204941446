import React, {Component} from 'react';

import "./style.css";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import GuestUserMenuBar from "../top-menu-bar/GuestUserMenuBar";
import {Link} from "react-router-dom";

export default class LandingView extends Component {


    render() {
        return (

            <div id="app">
                <LoadingSpinner/>

                <nav className="navbar navbar-expand-md navbar-light navbar-laravel">
                    <GuestUserMenuBar/>
                </nav>

                <main className="py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="container text-center">

                                    <div className="looking-to-sign-up mt-5">
                                        <div className="first-section">
                                            <i className="fas fa-exclamation-triangle"></i>
                                            <h4 className="macroactive-title">Looking to sign up with EasyMacroPlanner?</h4>
                                            <p className="fc-ternary">To sign up with EasyMacroPlanner platform you need to
                                                get an invite from
                                                your account manager. Please ask your account manager to send an invite
                                                and follow the link
                                                in the email.</p>
                                        </div>
                                        <div className="second-section">
                                            <p>Already a member?</p>
                                            <Link to="/login" className="btn btn-primary">Login</Link>
                                        </div>
                                        <div className="third-section">
                                            <p>EasyMacroPlanner Limited<br/>
                                                2A Westpark Drive, Auckland, Auckland 0618, NZ</p>
                                            <a href="#">support@easymacroplanner.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
