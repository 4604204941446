import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import BackToHome from "../user-dahsboard/BackToHome";
import './mandrill.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import Logger from "../../core/util/Logger";
import {checkMandrillDomainVerificationEmailForwardedAction} from "../../actions/user/check-user-has-forwared-mandrill-domain-verification-email-action";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";


class MandrillCheckForwardedEmail extends Component {

    constructor() {
        super();
        this.state = {
            start_checking_mandrill_email_forward: false,
            user_has_not_forwarded_email: false,
        };
    }


    componentDidMount() {
        this.checkUserHasForwardedEmail();
    }

    componentDidUpdate() {
        this.checkUserHasForwardedEmail();
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className="container">
                    <div className="card content-box">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main className="py-4"
                              style={this.state.start_checking_mandrill_email_forward === true ? {display: 'block'} : {display: 'none'}}>
                            <div className="container text-center">
                                {/*<div className="content-box">*/}

                                    <div className="mandrill-forward-check">
                                        <div className="first-section">
                                            <i className="fas fa-redo-alt fa-spin"></i>
                                            <p>Checking our email inbox </p>
                                        </div>
                                    </div>

                                {/*</div>*/}
                            </div>
                        </main>
                    </div>
                </div>


                <div tabIndex="-1" role="dialog"
                     style={this.state.user_has_not_forwarded_email === true ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content text-center mandrill-popup">
                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        We did not get your email
                                    </h5>
                                </div>

                                <div className="modal-body email-check">

                                    We didn't get your email. Did you forward it to verifications@inbound.easymacroplanner.com ?
                                    Please try again and if problem persist contact your account manager.
                                <div className="popup-footer-sec">
                                    <div>
                                        <a onClick={this.handleStartCheckingUserEmailForward.bind(this)}
                                           className="btn btn-primary">Recheck</a>
                                    </div>
                                    <br/>
                                    <div>
                                        <a onClick={this.startOverAgainResendEmail.bind(this)}
                                           className="btn btn-primary">Start Over</a>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    checkUserHasForwardedEmail() {
        //check whether the user has forwarded the email on page load or refresh
        if (this.props.user != null
            && this.props.user.authenticated === true
            && this.state.start_checking_mandrill_email_forward === false
            && this.props.mandrill_verification_forwarded == null) {

            this.handleStartCheckingUserEmailForward();
        }

        //displaying the error popup or redirect to dashboard(on completion)
        if (this.props.mandrill_verification_forwarded != null
            && this.props.mandrill_verification_forwarded.data.forwarded === true
            && this.state.start_checking_mandrill_email_forward === true) {
            Logger.info("email forwarded is completed. re-directing to the user's dashboard");
            this.props.history.push("/user/home");
        } else if (this.props.mandrill_verification_forwarded != null
            && this.props.mandrill_verification_forwarded.data.forwarded === false
            && this.state.user_has_not_forwarded_email === false
            && this.state.start_checking_mandrill_email_forward === true) {
            Logger.info("display the popup to show email is not forwarded");
            this.setState({user_has_not_forwarded_email: true, start_checking_mandrill_email_forward: false});
        }
    }

    startOverAgainResendEmail() {
        Logger.info("start over again to resend mandrill verification email");
        // this.setState({start_checking_mandrill_email_forward: false, user_has_not_forwarded_email: false});
        this.props.resetReduxStore('RESET_MANDRILL_VERIFICATION_EMAIL_CHECK_STORE');
        this.props.history.push("/mandrill/send-verification");
    }

    handleStartCheckingUserEmailForward() {
        Logger.info("start fetching user domain forwarded status");
        this.props.resetReduxStore('RESET_MANDRILL_VERIFICATION_EMAIL_CHECK_STORE');
        this.setState({start_checking_mandrill_email_forward: true, user_has_not_forwarded_email: false});
        let token = this.props.user.data.auth.token;
        this.props.checkUserForwardedVerificationEmail(token);
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        mandrill_verification_forwarded: state.mandrill_verification_forwarded
    }
};

const actions = {
    resetReduxStore: resetReduxStoreAction,
    checkUserForwardedVerificationEmail: checkMandrillDomainVerificationEmailForwardedAction
};

export default withRouter(connect(mapStateToProps, actions)(MandrillCheckForwardedEmail));
