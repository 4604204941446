import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../../common/AuthenticatedComponent';
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Logger from "../../../core/util/Logger";
import {addExistingDomainDetailsAction} from "../../../actions/user/add-existing-domain-details-action";
import {Link, withRouter} from "react-router-dom";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import BackToHome from "../../user-dahsboard/BackToHome";
import AccountManagerHelp from "../../top-menu-bar/AccountManagerHelp";
import TopMenuBar from "../../top-menu-bar/TopMenuBar";

class ExistingDomainDetails extends Component {

    constructor() {
        super();
        this.state = {
            show_domain_search_view: true,
            show_domain_reserve_view: false,
            show_account_manager_details: false
        }
    }


    componentDidUpdate() {
        Logger.info("updated", this.props);
        if (this.props.existing_domain != null && this.props.existing_domain.success === true) {
            Logger.info("redirect to dns configurations view");
            this.props.history.push("/domains/setup");
        }
    }

    render() {
        return (
            <div id="app" className={"search-name"} onClick={() => this.onClickShowAccountManagerDetails(this, false)}>

                <AuthenticatedComponent/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <div className={'container'}>
                    <div className="card content-box ">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main>
                            <Formik
                                initialValues={{
                                    domain: '',
                                    sub_domain: 'members',
                                }}
                                validationSchema={Yup.object().shape({
                                    domain: Yup.string()
                                        .required('domain is required'),
                                    sub_domain: Yup.string().required('sub domain is required')
                                })}
                                onSubmit={data => {
                                    this.handleDomainDetailSubmission(data);
                                }}
                                render={({values, errors, status, touched}) => (
                                    <Form>
                                        <div className="introing-new-domain">
                                            <div className="first-section">
                                                <h3>Existing Domain</h3>
                                            </div>
                                            <div className="third-section">

                                                <div id="existing-domain-search" className={"col-md-12"}>
                                                    <div className={"col-md-12"}>
                                                        <div style={(this.props.existing_domain != null && this.props.existing_domain.success === false)
                                                                ? {display: 'flex'} : {display: 'none'}}
                                                            className="alert alert-danger existing-domain-error-alert" role="alert">
                                                            {
                                                                this.props.existing_domain != null && this.props.existing_domain.data.message
                                                            }
                                                        </div>

                                                        <div className="form-width">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="domain">Domain</label>
                                                                    </div>
                                                                    <div className="col-md-9">
                                                                        <Field name="domain" type="text"
                                                                               className={'form-control' + (touched.domain && errors.domain ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage name="domain" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="form-group">
                                                                <div className="row subdomain">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="sub_domain">Sub
                                                                            Domain</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Field name="sub_domain" type="text"
                                                                               className={'form-control' + (touched.sub_domain && errors.sub_domain ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage name="sub_domain"
                                                                                      component="div"
                                                                                      className="invalid-feedback"/>
                                                                        <p className={"secodry-text-g"}>E.g: for
                                                                            premium.yourwebsite.com type
                                                                            "premium".</p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <p>{values.domain}</p>

                                                                    </div>
                                                                    <div className="col-md-12">

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="dns-footer">
                                                <Link to={"/domains/main"} className="btn btn-primary">Back</Link>
                                                &nbsp;
                                                <button type="submit"
                                                        className="btn btn-primary">Next
                                                </button>
                                            </div>

                                        </div>
                                    </Form>

                                )}
                            />

                            {/*</div>*/}

                        </main>
                    </div>
                </div>

            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }

    handleDomainDetailSubmission(data) {
        this.props.resetReduxStore('RESET_EXISTING_DOMAIN_DETAILS');
        let token = this.props.user.data.auth.token;
        this.props.addExistingDomainDetails(data, token);
    }
}

const actions = {
    addExistingDomainDetails: addExistingDomainDetailsAction,
    resetReduxStore: resetReduxStoreAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        existing_domain: state.existing_domain
    }
};

export default withRouter(connect(mapStateToProps, actions)(ExistingDomainDetails));
