import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {archiveUserAction} from "../../../actions/admin/archive-user-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";

// let defaultState = {start_archive_user: false, message_type: null, message_text: null};
let defaultState = {start_archive_user: false, user_archived_success: false, message: null};

class ArchiveMemberConfirmationPopup extends Component {


    constructor() {
        super();
        this.state = defaultState;
    }

    componentDidMount() {
        this.setState(defaultState);
    }

    componentDidUpdate() {
        if (this.props.user_archive != null && this.state.start_archive_user === true) {

            // if (this.props.user_archive.success === true) {
            //     this.setState({message_type: 'success', message_text: this.props.user_archive.message});
            // } else {
            //     this.setState({message_type: 'error', message_text: this.props.user_archive.message});
            // }
            if (this.props.user_archive.success === true) {
                this.setState({
                    start_archive_user: false,
                    user_archived_success: true,
                    message: this.props.user_archive.message
                });
            } else {
                this.setState({
                    start_archive_user: false,
                    user_archived_success: false,
                    message: this.props.user_archive.message
                });
            }
        }
    }

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'archive_member_confirmation_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">

                <div className="overlay">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div
                            style={this.state.start_archive_user === false && this.state.user_archived_success === false ? {display: 'block'} : {display: 'none'}}
                            className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Archive</h5>
                                <button type="button"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure that you want to
                                archive {this.props.member_profile != null ? this.props.member_profile.data.full_name : ''}?
                            </div>

                            <div
                                style={this.state.message != null && this.state.message.type === 'success' ? {display: 'block'} : {display: 'none'}}
                                className={"alert alert-success"} role="alert">
                                {this.state.message !== null ? this.state.message.text : ''}
                            </div>

                            <div
                                style={this.state.message != null && this.state.message.type === 'error' ? {display: 'block'} : {display: 'none'}}
                                className={"alert alert-danger"} role="alert">
                                {this.state.message !== null ? this.state.message.text : ''}
                            </div>

                            <div className="modal-footer">
                                <button type="button"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary" data-dismiss="modal">Cancel
                                </button>
                                <button type="button"
                                        onClick={this.onClickArchiveMember.bind(this)}
                                        className="btn btn-primary">Archive
                                </button>
                            </div>
                        </div>


                        <div
                            style={this.state.start_archive_user === false && this.state.user_archived_success === true ? {display: 'block'} : {display: 'none'}}
                            className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Success</h5>
                                <button type="button"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div
                                    style={this.state.message != null && this.state.message.type === 'success' ? {display: 'block'} : {display: 'none'}}
                                    className={"alert alert-success"} role="alert">
                                    {this.state.message !== null ? this.state.message.text : ''}
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button type="button"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-primary" data-dismiss="modal">Close
                                </button>
                            </div>
                        </div>


                        <div className="modal-content"
                             style={this.state.start_archive_user === true ? {display: 'block'} : {display: 'none'}}>
                            <div className="modal-body jrf-popup">
                                <p className="text-center">
                                    Please wait... user is being archived.
                                </p>
                                <img src="../../../images/spinner.gif" className="loder"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("member archive verification popup is closed");
        this.setState(defaultState);
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");

        let payload = {
            id: "archive_member_confirmation_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);

        //this is to reflect the latest archived changes in the list
        if (this.props.user_archive != null && this.props.user_archive.success === true) {
            Logger.info("reloading the latest member list");
            this.props.resetReduxStore("MEMBER_ARCHIVE_RESET");
            this.props.getAllMembers(this.props.user.data.auth.token,this.props.filter);
        }
    }


    onClickArchiveMember() {
        this.props.resetReduxStore("MEMBER_ARCHIVE_RESET");
        this.setState({start_archive_user: true});
        let userId = this.props.member_profile.data.user_id;
        let token = this.props.user.data.auth.token;
        Logger.info("request to archive member  [" + userId + "] ");
        // this.onClosePopupHandler();
        this.props.archiveUser(userId, token);
    }
}


const mapStateToProps = (state) => {
    return {
        member_profile: state.member_profile,
        popup: state.popup,
        user: state.user,
        user_archive: state.user_archive
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    archiveUser: archiveUserAction,
    resetReduxStore: resetReduxStoreAction,
    getAllMembers: getAllRegisteredMembersAction
};


export default withRouter(connect(mapStateToProps, actions)(ArchiveMemberConfirmationPopup));