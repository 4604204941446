import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import Logger from "../../core/util/Logger";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from 'query-string'
import {getStripeConnectUrlAction} from "../../actions/user/get-stripe-authorization-url";
import {handlerLoaderVisibility} from "../../actions/handle-loader-visibility-action";
import {completeStripeConnectAction} from "../../actions/user/complete-stripe-connect-action";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";
import BackToHome from "../user-dahsboard/BackToHome";
import './stripe-payment.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";


class StripePayment extends Component {

    constructor() {
        super();
        this.state = {
            start_fetching_authorization_url: false,
            authorization_url: null,
            error_message: null,
            send_stripe_connect_code: false,
            show_account_manager_details: false
        };
    }

    componentDidMount() {
        let token = this.props.user.data.auth.token;
        const queryParams = queryString.parse(this.props.location.search);

        if (token !== undefined && queryParams.code === undefined) {
            this.props.getStripeConnectUrl(token);
        }
    }


    componentDidUpdate() {
        const queryParams = queryString.parse(this.props.location.search);
        Logger.info("query params", queryParams);

        let token = this.props.user.data.auth.token;
        if (token !== undefined && this.state.start_fetching_authorization_url === false && queryParams.code === undefined) {
            this.setState({start_fetching_authorization_url: true});
            this.props.getStripeConnectUrl(token);
        }

        if (this.props.stripe_url != null
            && this.props.stripe_url.data.url !== undefined
            && this.state.authorization_url === null) {
            this.setState({authorization_url: this.props.stripe_url.data.url})
        }

        //updating the error message (received from stripe)
        if (this.state.error_message === null && queryParams.error_description !== undefined) {
            this.setState({error_message: queryParams.error_description});
        }

        //updating the error message (received from api backend)
        if (this.state.error_message === null
            && this.props.stripe_details != null
            && this.props.stripe_details.success === false
            && this.props.stripe_details.data.message !== undefined) {
            this.setState({error_message: this.props.stripe_details.data.message});

            this.props.getStripeConnectUrl(token);
        }

        if (this.state.send_stripe_connect_code === false && queryParams.code !== undefined) {
            this.setState({send_stripe_connect_code: true});
            Logger.info("completing the stripe connect");
            this.props.completeStripeConnect(queryParams.code, token);
        }

        //redirect and display success message
        if (this.props.stripe_details != null
            && this.props.stripe_details.success === true
            && this.props.stripe_details.data.message !== undefined) {
            Logger.info("stripe integration completed. returning back to user's dashboard");
            this.props.history.push("/user/home", {message: this.props.stripe_details.data.message});
            this.props.resetReduxStore('RESET_STRIPE_CONNECT');
        }
    }

    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                {/*<TopMenuBar/>*/}
                <div className="container">
                    <div className="mt-5 mb-5 pl-2 pr-2 pb-2">

                        <main className="py-4">
                            <div className="container">

                                <div style={this.state.error_message !== null ? {display: 'block'} : {display: 'none'}}
                                     className="alert alert-danger" role="alert">
                                    {this.state.error_message}
                                </div>

                                <div className="dashboard-head text-center">
                                    {/*<h4 className="macroactive-title">To collect payments from your customers we need to connect with your Stripe account. Press Connect to Stripe button below.</h4>*/}
                                    <p className="fc-ternary striped-title ">To collect payments from your customers we
                                        need to connect with your Stripe account. <br/>Press Connect to Stripe button
                                        below.</p>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <a href={this.state.authorization_url}
                                           onClick={this.onClickConnectWithStripe.bind(this)}
                                           className="btn btn-primary">
                                            Connect to Stripe
                                        </a>
                                        <p className={'striped-sub-text'}>If you currently don't own a Stripe account.
                                            You can create one after pressing the button.</p>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }


    onClickConnectWithStripe() {
        Logger.info("connect with stripe");
        this.props.displayLoader(true);
        this.setState({error_message: null});
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        stripe_url: state.stripe_url,
        stripe_details: state.stripe_details
    }
};

const actions = {
    getStripeConnectUrl: getStripeConnectUrlAction,
    displayLoader: handlerLoaderVisibility,
    completeStripeConnect: completeStripeConnectAction,
    resetReduxStore: resetReduxStoreAction
};

export default withRouter(connect(mapStateToProps, actions)(StripePayment));
