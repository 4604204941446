import React, {Component} from 'react';
import './sign-up.css';
import PersonalDetails from './personal-details/PersonalDetails';
// import CompanyDetails from './company-details/CompanyDetails';
// import ProgramDetails from './program-customization-details/ProgramDetails';
import {reduxForm, getFormValues, Field} from 'redux-form';
import {connect} from 'react-redux'
import {uploadProfilePicture} from '../../actions/index';
import Logger from "../../core/util/Logger";
import validator from 'validator';
import {createNewMember} from "../../actions/user/register-member-action";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {withRouter} from 'react-router-dom';
import TopMenuBar from '../top-menu-bar/TopMenuBar'
import {getUserDetailsByTokenAction} from "../../actions/user/get-userdetails-by-token-action";
import {validateMemberRegistrationLinkAction} from "../../actions/user/validate-member-registration-link-hash-action";
// import {FormField} from "../../core/util/FormField";

let displayForms = {
    personal_details: false,
    company_details: false,
    program_details: false
};

class SignUpForm extends Component {

    constructor() {
        super();
        this.state = {
            personal_details: true,
            company_details: false,
            program_details: false,
            show_registration_form: false
        };
    }

    async componentDidMount() {
        let linkHash = this.props.match.params.id;
        await this.props.validateMemberRegistrationLink(linkHash);
        // Logger.info("===========", this.props.member_invitation_validity);

        if (this.props.member_invitation_link.success === true) {
            this.setState({show_registration_form: true});
        }

        let key = "form-data";
        let objectString = localStorage.getItem(key);
        let storeData = {};
        if (objectString !== undefined && objectString !== null) {
            storeData = JSON.parse(objectString);
        }

        if (this.props.member_invitation_link != null &&
            this.props.member_invitation_link.data !== undefined &&
            this.props.member_invitation_link.data.email !== undefined) {
            Logger.info("pre-fill user's email");
            storeData.email = this.props.member_invitation_link.data.email;
        }
        this.props.initialize(storeData);
    }


    componentDidUpdate() {
        if (this.props.user !== undefined && this.props.user.authenticated) {
            Logger.info("redirecting to the user dashboard ", this.props);
            localStorage.setItem("user-token", this.props.user.data.auth.token);
            //removed the saved form from localstorage
            localStorage.removeItem("form-data");
            this.props.history.push("/user/home");
        }
    }

    render() {
        return (
            <div id="app">
                <LoadingSpinner/>

                <TopMenuBar/>

                <main className="py-4">
                    <div className="container">

                        <div style={this.props.member_invitation_link != null
                        && this.props.member_invitation_link.success === false ? {display: 'block'} : {display: 'none'}}
                             className="alert alert-danger" role="alert">
                            {
                                this.props.member_invitation_link != null
                                && this.props.member_invitation_link.data != null
                                && this.props.member_invitation_link.data.message
                            }
                        </div>

                        <div className="row justify-content-center"
                             style={this.state.show_registration_form === true ? {display: 'block'} : {display: 'none'}}>
                            <div className="col-md-10">
                                <div>
                                    <h3>Sign up with Easy Macro Planner</h3>
                                </div>
                                <div className="alert alert-danger"
                                     style={(this.props.member !== undefined && this.props.member.success === false) &&
                                     this.props.member.message !== undefined &&
                                     (this.props.member.message.type === 'error') ? {display: 'block'} : {display: 'none'}}
                                     role="alert">
                                    {
                                        (this.props.member !== undefined && this.props.member.message !== undefined) ? this.props.member.message.text : ''
                                    }
                                </div>

                                <div>
                                    <form onSubmit={this.props.handleSubmit(this.onSubmitHandler.bind(this))}
                                          encType={"multipart/form-data"}>

                                        <div id="accordionExample" className="accordion reg">

                                            <PersonalDetails
                                                // onAccordionClick={this.onClickAccordionHandler.bind(this)}
                                                //              expandOnSubmit={this.state.personal_details}
                                                             onClickCompleteBtn={this.onClickCompleteBtn.bind(this)}
                                                             persistFormData={persistFormData}/>

                                            {/*<CompanyDetails onAccordionClick={this.onClickAccordionHandler.bind(this)}*/}
                                            {/*                expandOnSubmit={this.state.company_details}*/}
                                            {/*                persistFormData={persistFormData}/>*/}

                                            {/*<ProgramDetails persistFormData={persistFormData}*/}
                                            {/*                appName={this.props.app_name}*/}
                                            {/*                onAccordionClick={this.onClickAccordionHandler.bind(this)}*/}
                                            {/*                expandOnSubmit={this.state.program_details}*/}
                                            {/*                onClickCompleteBtn={this.onClickCompleteBtn.bind(this)}/>*/}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }

    onSubmitHandler = (data) => {
        Logger.info("submitted from logger", data);
        //adding the registration link hash
        data.member_invitation_hash = this.props.match.params.id;

        this.props.completeMemberRegistration(data);
    };


    // onClickAccordionHandler(accordionName) {
    //     Logger.info("display accordion [" + accordionName + "] as active");
    //
    //     displayForms = {
    //         personal_details: false,
    //         company_details: false,
    //         program_details: false
    //     };
    //     if (accordionName === 'personal_details') {
    //         displayForms.personal_details = !this.state.personal_details;
    //     }
    //     if (accordionName === 'company_details') {
    //         displayForms.company_details = !this.state.company_details;
    //     }
    //     if (accordionName === 'program_details') {
    //         displayForms.program_details = !this.state.program_details;
    //     }
    //     this.setState(displayForms);
    // }

    onClickCompleteBtn() {
        Logger.info("on click complete btn. set to initial state", this.props.form_values);
        //hide all the accordions on click submit complete btn
        displayForms = {
            personal_details: false,
            company_details: false,
            program_details: false
        };
        this.setState(displayForms);
        validate(this.props.form_values);
        this.setState(displayForms);
    }
}

const persistFormData = (event) => {
    Logger.info(event.currentTarget);

    let key = "form-data";
    let objectString = localStorage.getItem(key);
    let storeData = {};

    if (objectString !== undefined && objectString !== null) {
        storeData = JSON.parse(objectString);
    }
    storeData[event.currentTarget.name] = event.currentTarget.value;

    //if the latest value is empty, we will remove it from local store
    if ((event.currentTarget.value).trim() === "") {
        delete storeData[event.currentTarget.name];
    }
    localStorage.setItem(key, JSON.stringify(storeData));
};


const validate = val => {
    const errors = {};

    // if (val === undefined) {
    //     displayForms.personal_details = true;
    //     displayForms.company_details = true;
    //     displayForms.program_details = true;
    //     return errors;
    // }

    if (!val.full_name) {
        Logger.info('Name is required');
        errors.full_name = 'The name field is required';
        displayForms.personal_details = true;
    }

    if (!val.email) {
        Logger.info('email is required');
        errors.email = 'Email is Required';
        displayForms.personal_details = true;
    } else if (!validator.isEmail(val.email)) {
        Logger.info('email is invalid');
        errors.email = 'Invalid email address';
        displayForms.personal_details = true;
    }

    if (!val.phone_number) {
        Logger.info("phone number is required");
        errors.phone_number = 'phone number is required';
        displayForms.personal_details = true;
    }

    if (!val.meal_plan) {
        Logger.info("meal plan is required");
        errors.meal_plan = 'meal plan is required';
        displayForms.personal_details = true;
    }

    // if ((val.birthday_month !== undefined && val.birthday_month !== 'default') && !val.birthday_day) {
    //     errors.birthday_day = 'day is required';
    //     displayForms.personal_details = true;
    // }
    //
    // if (isValidDay(val.birthday_day) && (val.birthday_month === undefined || val.birthday_month === "default")) {
    //     errors.birthday_month = 'month is required';
    //     displayForms.personal_details = true;
    // }
    //
    // if (val.birthday_day !== undefined && val.birthday_day.trim() !== "" && !isValidDay(val.birthday_day)) {
    //     errors.birthday_day = 'Invalid day';
    //     displayForms.personal_details = true;
    // }
    //
    // if (!val.company_name) {
    //     Logger.info("company name is required");
    //     errors.company_name = 'Company name is Required';
    //     displayForms.company_details = true;
    // }

    // if (!val.company_address) {
    //     Logger.info("company address is required");
    //     errors.company_address = 'Company address is required';
    //     displayForms.company_details = true;
    // }
    Logger.info("validation completed");
    return errors;
};


// const isValidDay = (val) => {
//     return val !== undefined && val !== '' && !isNaN(val) && (val > 0 && val < 32);
// };

const mapStateToProps = (state) => {
    let formValues = getFormValues('signUp')(state);
    return {
        app_name: formValues !== undefined ? formValues.app_name : '',
        member: state.member,
        form_values: getFormValues('signUp')(state),
        user: state.user,
        member_invitation_link: state.member_invitation_link
    }
};

let actions = {
    uploadProfileImage: uploadProfilePicture,
    completeMemberRegistration: createNewMember,
    getUserDetails: getUserDetailsByTokenAction,
    validateMemberRegistrationLink: validateMemberRegistrationLinkAction
};


export default withRouter(connect(mapStateToProps, actions)(reduxForm({
    form: 'signUp',
    validate,
})(SignUpForm)));



