import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import BackToHome from "../user-dahsboard/BackToHome";
import './stripe-connect-instruction.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import StripePayment from "./StripePayment";


class StripeConnectInstruction extends Component {

    constructor() {
        super();
        this.state = {
            show_account_manager_details: false
        };
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className="container">
                    <div className="card content-box mb-5">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                                            <StripePayment/>

                        {/*<main className="py-4">*/}
                        {/*    <div className="container text-center">*/}

                        {/*        <div className="stripe-ui">*/}
                        {/*            <div className="first-section text-center">*/}
                        {/*                <p>To collect payments from your customers we need to connect with your Stripe*/}
                        {/*                    account. <br/>Please add below email as an admin to your Stripe account.</p>*/}
                        {/*                <p className="title">stripe2@macroactive.com</p>*/}
                        {/*            </div>*/}
                        {/*            <div className="second-section">*/}
                        {/*                <a className="btn btn-primary" href="https://dashboard.stripe.com/settings"*/}
                        {/*                      target="_blank">*/}
                        {/*                    Go to Stripe*/}
                        {/*                </a>*/}
                        {/*                <div className="img-wrap">*/}
                        {/*                    <img src="../images/my-fitness-empire.jpg"/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="dns-footer">*/}
                        {/*                <Link to={"/user/home"} className="btn btn-primary">Next</Link>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</main>*/}
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}

const mapStateToProps = (state) => {
    return {}
};

export default withRouter(connect(mapStateToProps, {})(StripeConnectInstruction));
