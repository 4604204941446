import React, {Component} from 'react';
import AuthenticatedComponent from '../common/AuthenticatedComponent';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from '../top-menu-bar/TopMenuBar';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getAllRegisteredMembersAction} from "../../actions/admin/get-registered-members-action";
import {getMemberProfileDetailsByUserIdAction} from "../../actions/admin/get-member-details-by-id-action";
import MemberList from './members/MembersList';
import Logger from "../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import InviteNewMember from "./InviteNewMember";
import ArchiveMemberConfirmationPopup from "./members/ArchiveMemberConfirmationPopup";

class AdminDashboard extends Component {

    constructor() {
        super();
        this.state = {selected_filter: 'me'};
    }

    render() {
        return (
            <div id="app">

                <AuthenticatedComponent/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <InviteNewMember/>

                <div className="container">
                    <div className="card mt-5 pl-5 pr-5 pb-5">
                        <div style={{padding: '10px'}}>

                            {/*Show trainers of*/}
                            {/*<div style={{padding: '10px'}} className="btn-group" role="group"*/}
                            {/*     aria-label="Basic example">*/}
                            {/*    <button type="button"*/}
                            {/*            onClick={() => this.onClickFilterMembers('me')}*/}
                            {/*            className={this.state.selected_filter === 'me' ? "btn btn-primary" : "btn btn-secondary"}>Me*/}
                            {/*    </button>*/}
                            {/*    <button type="button"*/}
                            {/*            onClick={() => this.onClickFilterMembers('all')}*/}
                            {/*            className={this.state.selected_filter === 'all' ? "btn btn-primary" : "btn btn-secondary"}>All*/}
                            {/*        Managers*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            <div className={'filter-wrap'}>
                                <p className={'filter-title'}>Show trainers of</p>
                                <div className="btn-group" role="group"
                                     aria-label="Basic example">
                                    <a onClick={() => this.onClickFilterMembers('me')}
                                       className={this.state.selected_filter === 'me' ? "btn btn-primary" : "btn btn-default second-btn"}>Me
                                    </a>
                                    <a onClick={() => this.onClickFilterMembers('all')}
                                       className={this.state.selected_filter === 'all' ? "btn btn-primary" : "btn btn-default second-btn"}>All
                                        Managers
                                    </a>
                                </div>

                            </div>
                            <a className={'btn btn-primary new-member-btn'}
                               onClick={this.onClickInviteNewMember.bind(this)}>Invite Trainer</a>


                        </div>

                        <main className="py-4">

                            <div
                                style={this.props.all_members !== null
                                && this.props.all_members.data.message !== undefined ? {display: 'block'} : {display: 'none'}}
                                className="alert alert-danger"
                                role="alert">
                                {
                                    (this.props.all_members !== null
                                        && this.props.all_members.data.message !== undefined)
                                        ? this.props.all_members.data.message : ''
                                }
                            </div>
                            <MemberList filter={this.state.selected_filter}/>
                        </main>
                    </div>
                </div>
                <ArchiveMemberConfirmationPopup filter={this.state.selected_filter}/>
            </div>
        );
    }


    onClickFilterMembers(filter) {
        Logger.info("filter the members [" + filter + "]");
        this.setState({selected_filter: filter});
        this.props.getAllMembers(this.props.user.data.auth.token, filter);
    }


    onClickInviteNewMember() {
        Logger.info("inviting new member");
        let payload = {
            id: "invite_new_member_popup",
            shouldDisplay: true
        };
        this.props.handlePopupVisibility(payload);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        all_members: state.all_members,
        member_profile: state.member_profile
    };
};

const actions = {
    getAllMembers: getAllRegisteredMembersAction,
    getMemberDetailsById: getMemberProfileDetailsByUserIdAction,
    handlePopupVisibility: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(AdminDashboard));
