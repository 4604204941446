import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const purchaseNewDomainAction = (domain, subDomain, token) => async dispatch => {

    let formData = new FormData();
    formData.append("domain", domain);
    formData.append("sub_domain", subDomain);
    formData.append("domain_type", 'new_domain');

    Logger.info("purchasing new domain", formData);
    let response = await RestApiService.post("/domains/purchase", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "BUYING_NEW_DOMAIN_SUCCESS",
            payload: response.data
        });
    }
    else {
        dispatch({
            type: "BUYING_NEW_DOMAIN_FAILED",
            payload: response.data
        });
    }
};