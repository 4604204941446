import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {handlerLoaderVisibility} from "../../actions/handle-loader-visibility-action";
import BackToHome from "../user-dahsboard/BackToHome";
import './mandrill.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import {getMemberDomainDetailsAction} from "../../actions/user/get-member-domain-details-action";
import Logger from "../../core/util/Logger";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {resendMandrillVerificationEmailByUser} from "../../actions/user/user-resend-mandrill-domain-verification-email-action";


class MandrillSendVerification extends Component {

    constructor() {
        super();
        this.state = {
            error_message: null,
            show_account_manager_details: false,
            start_fetching_member_domain_details: false,
            sending_mandrill_verification_email: false,
            member_domain_name: undefined
        };
    }


    async componentDidMount() {
        await this.setMemberDomainDetails();
    }

    async componentDidUpdate() {
        await this.setMemberDomainDetails();

        if (this.props.mandrill_verification != null && this.props.mandrill_verification.success === true && this.state.sending_mandrill_verification_email === true) {
            Logger.info("next view");
            this.setState({sending_mandrill_verification_email: false});
            this.props.history.push("/mandrill/receive-verification");
        }

        if (this.props.mandrill_verification != null && this.props.mandrill_verification.success === false && this.state.error_message == null) {
            Logger.info("updating error message");
            this.setState({error_message: this.props.mandrill_verification.data.message});
        }
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className="container">
                    <div className="card content-box">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main className="py-4">

                            <div className="container text-center">
                                {/*<div className="content-box">*/}
                                    <div className="mandrill-varify-email">
                                        <div className="first-section">
                                            <p className="fc-ternary">In order to send emails on your behalf, our
                                                service provider Mandrill wants to
                                                verify your domain ownership.</p>
                                        </div>

                                        <div
                                            style={this.state.error_message != null ? {display: 'block'} : {display: 'none'}}
                                            className="alert alert-danger" role="alert">
                                            {
                                                this.state.error_message
                                            }
                                        </div>

                                        <Formik
                                            ref={this.formik}
                                            initialValues={{
                                                mailBoxId: ''
                                            }}
                                            validationSchema={Yup.object().shape({
                                                mailBoxId: Yup.string()
                                                    .required("Name is required"),
                                            })}
                                            onSubmit={fields => {
                                                Logger.info("handle submission");
                                                this.handleMandrillVerificationEmail(fields);
                                            }}
                                            render={({errors, status, touched}) => (
                                                <Form>
                                                    <div className="second-section">
                                                        <p>Confirm your email address to send the verification email</p>
                                                        <div className="input-feild-width">
                                                            <div className="input-group mb-3 mandrill-input">

                                                                <Field name="mailBoxId" type="text"
                                                                       className={'form-control' + (errors.mailBoxId && touched.mailBoxId ? ' is-invalid' : '')}/>

                                                                <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    {
                                                                        "@"+this.state.member_domain_name
                                                                    }
                                                                </span>
                                                                </div>

                                                                <ErrorMessage name="mailBoxId" component="div"
                                                                              className="invalid-feedback"/>
                                                            </div>
                                                        </div>
                                                        {/*<a href="#" className="btn btn-primary">Verify</a>*/}

                                                        <button type={"submit"} className="btn btn-primary">
                                                            Verify
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        />
                                    </div>
                                {/*</div>*/}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    async setMemberDomainDetails() {
        let token = this.props.user.data.auth.token;
        if (this.props.member_domain === null && this.state.start_fetching_member_domain_details === false && token !== undefined) {
            Logger.info("start fetching member domain details");
            this.setState({start_fetching_member_domain_details: true});
            this.props.getMemberDomainDetails(token);
        } else if ((this.props.member_domain != null &&
            this.props.member_domain.success === true && this.state.member_domain_name === undefined)) {
            this.setState({member_domain_name: this.props.member_domain.data.domain});
        }
    }


    handleMandrillVerificationEmail(data) {
        this.setState({error_message: null, sending_mandrill_verification_email: true});
        let token = this.props.user.data.auth.token;
        this.props.sendMandrillVerificationEmail(token, data.mailBoxId, true);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        member_domain: state.member_domain,
        mandrill_verification: state.mandrill_verification
    }
};

const actions = {
    displayLoader: handlerLoaderVisibility,
    getMemberDomainDetails: getMemberDomainDetailsAction,
    sendMandrillVerificationEmail: resendMandrillVerificationEmailByUser
};

export default withRouter(connect(mapStateToProps, actions)(MandrillSendVerification));
