import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {handlerLoaderVisibility} from "../../actions/handle-loader-visibility-action";
import BackToHome from "../user-dahsboard/BackToHome";
import './mandrill.css';
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";


let forwardEmail = "verifications@inbound.easymacroplanner.com";

class MandrillForwardVerification extends Component {

    constructor() {
        super();
        this.state = {
            error_message: null,
            show_account_manager_details: false,
            start_copying_email: false,
        };
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent display_loading_screen={false}/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className="container">
                    <div className="card content-box">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main className="py-4">
                            <div className="container text-center">
                                {/*<div className="content-box">*/}
                                <div className="mandrill-forward">
                                    <div className="first-section">
                                        <p>Great! Now forward it to</p>
                                        <div className="input-feild-width">
                                            <form>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" value={forwardEmail}
                                                           readOnly={true}
                                                           id={"forwarding-email"}
                                                           name={"forwarding-email"}/>
                                                    <div className="input-group-append">
                                                        <button className="btn m-btn-color btn-outline-secondary"
                                                                onClick={this.onClickCopyToClipBoard}
                                                                type="button">
                                                            Copy
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <p className={'status-copied'} style={this.state.start_copying_email ? {display: 'block'} : {display: 'none'}}>
                                                Copied
                                            </p>
                                        </div>

                                        <p>Did you forward it?</p>
                                        <a onClick={this.onClickCheckForwardedEmail.bind(this)}
                                           className="btn btn-primary white-btn">Yes</a>
                                    </div>
                                    <div className="second-section mt-2">
                                        <a onClick={this.onClickResendMandrillVerificationEmail.bind(this)}>
                                            Resend
                                        </a>
                                    </div>
                                </div>
                                {/*</div>*/}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    onClickCopyToClipBoard = () => {
        this.setState({start_copying_email: true});
        document.getElementById('forwarding-email').select();
        document.execCommand("copy");
        setTimeout(this.setState.bind(this, {start_copying_email: false}), 1000);
    };

    onClickResendMandrillVerificationEmail = () => {
        this.props.history.push("/mandrill/send-verification");
    };

    onClickCheckForwardedEmail = () => {
        this.props.history.push("/mandrill/check-email");
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const actions = {
    displayLoader: handlerLoaderVisibility,
};

export default withRouter(connect(mapStateToProps, actions)(MandrillForwardVerification));
