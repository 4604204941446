import React, {Component} from 'react';
import {Field} from 'redux-form';
import '../sign-up.css';
import {FormField} from "../../../core/util/FormField";
// import SizeGuidePopUp from '../SizeGuidePopUp';
// import {NavLink} from "react-router-dom";
// import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


// let months = [
//     {"key": "default", "value": "Select month"},
//     {"key": "January", "value": "January"},
//     {"key": "February", "value": "February"},
//     {"key": "March", "value": "March"},
//     {"key": "April", "value": "April"},
//     {"key": "May", "value": "May"},
//     {"key": "June", "value": "June"},
//     {"key": "July", "value": "July"},
//     {"key": "August", "value": "August"},
//     {"key": "September", "value": "September"},
//     {"key": "October", "value": "October"},
//     {"key": "November", "value": "November"},
//     {"key": "December", "value": "December"},
// ];

class PersonalDetails extends Component {

    render() {
        return (
            <div className="card">


                {/*<div id="headingOne" className="card-header">*/}
                {/*    <h2 className="mb-0">*/}
                {/*        /!*<button*!/*/}
                {/*        /!*    onClick={() => this.props.onAccordionClick('personal_details')}*!/*/}
                {/*        /!*    type="button" data-toggle="collapse"*!/*/}
                {/*        /!*    aria-expanded=" true "*!/*/}
                {/*        /!*    className="btn btn-link ">*!/*/}
                {/*        <p className={"btn btn-link"}>*/}
                {/*            Your information*/}
                {/*        </p>*/}
                {/*        /!*</button>*!/*/}
                {/*    </h2>*/}
                {/*</div>*/}
                <div id="collapseOne" aria-labelledby="headingOne"
                     data-parent="#accordionExample">
                    <div className="card-body">


                        <div className="form-group row">
                            <label htmlFor="full_name" className="col-md-4 col-form-label text-md-right">What
                                is your full name? <span
                                    className="text-danger"> *</span></label>

                            <div className="col-md-6">

                                <Field name="full_name" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={!this.props.profile_edit ? this.props.persistFormData : undefined}
                                       label="Full name"/>
                            </div>
                        </div>
                        <div className="form-group row"><label htmlFor="email"
                                                               className="col-md-4 col-form-label text-md-right">What
                            is your email?<span
                                className="text-danger"> *</span></label>
                            <div className="col-md-6">

                                <Field name="email" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       style={{display: 'none'}}
                                       disabled={this.props.profile_edit ? "disabled" : ""}
                                       onChange={this.props.persistFormData}
                                       label="Email"/>
                            </div>
                        </div>
                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">What is
                            your
                            best phone number? </label>

                            <div className="col-md-6">

                                <Field name="phone_number" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={this.props.persistFormData}
                                       label="Phone number"/>
                            </div>
                        </div>


                        <div className="form-group row">
                            <label className="col-md-4 col-form-label text-md-right">
                                Product name for the meal plan?
                            </label>

                            <div className="col-md-6">

                                <Field name="meal_plan" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={this.props.persistFormData}
                                       label="Meal plan"/>

                                <small id="" className="form-text text-muted">
                                    E.g Personalized meal plan
                                </small>
                            </div>
                        </div>


                        {/*<button type="button" onClick={() => this.props.onAccordionClick('company_details')}*/}
                        {/*        className="float-right btn btn-success  mb-5">*/}
                        {/*    Next*/}
                        {/*</button>*/}

                        <Field label="Complete"
                               value="Complete"
                               onClick={this.props.onClickCompleteBtn}
                               component={FormField.renderSubmitButton}
                               className="float-right btn btn-success  mb-5"/>

                    </div>
                </div>
            </div>
        );
    }

    // onClickSizeGuide(url) {
    //     Logger.info("display size guide  popup");
    //     let payload = {
    //         id: "t_shirt_size_guide_popup",
    //         shouldDisplay: true,
    //         url: url
    //     };
    //     this.props.handlePopupVisibility(payload);
    // }
}

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};

const mapStateToProps = (state) => {
    return {
        popup: state.popup,
    };
};

export default withRouter(connect(mapStateToProps, actions)(PersonalDetails));

