import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

class GuestUserMenuBar extends Component {

    render() {
        return (
            <div style={this.props.user.authenticated ? {display: 'none'} : {display: 'flex'}}
                 className="container">
                <a className="navbar-brand">

                    <img style={{width: 130, marginBottom: 6}}
                         src="../images/easy_macro_planner_logo.png"
                         alt="MacroActive"/>
                </a>
                <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation"
                        className="navbar-toggler"><span className="navbar-toggler-icon"></span></button>
                <div id="navbarSupportedContent" className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto"></ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="/login" className="nav-link">Login</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, {})(GuestUserMenuBar);