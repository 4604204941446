import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getAllRegisteredMembersAction = (token, filter) => async dispatch => {
    Logger.info("getting all registered members by admin token [" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/admin/members?filter=" + filter, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("all registered members received successfully");
        dispatch({
            type: "GET_ALL_REGISTERED_MEMBERS_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting all registered members ");
        dispatch({type: "GET_ALL_REGISTERED_MEMBERS_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};