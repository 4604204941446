import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const checkMandrillDomainVerificationEmailForwardedAction = (token) => async dispatch => {
    Logger.info("checking whether the user has forwarded the mandrill domain verification email [" + token + "] ");

    let response = await RestApiService.get("/mandrill/forward-check", {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 5 seconds to display the loading icon in the component
    await new Promise(resolve => setTimeout(resolve, 5000));

    if (response.status === 200) {
        Logger.info("successfully received the response");
        dispatch({
            type: "MANDRILL_VERIFICATION_FORWARDED_RESPONSE_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting the response", response.data);
        dispatch({type: "MANDRILL_VERIFICATION_FORWARDED_RESPONSE_FAILED", payload: response.data});
    }
};