import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";


class MemberDetailsPopup extends Component {

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'member_details_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Submission
                                </h5>
                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td><b>Name</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.full_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Email</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.email : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Domain</b></td>
                                        <td>{(this.props.member_profile !== null && this.props.member_profile.data.domain_details != null) ? this.props.member_profile.data.domain_details.domain : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Sub Domain</b></td>
                                        <td>{(this.props.member_profile !== null && this.props.member_profile.data.domain_details != null) ? this.props.member_profile.data.domain_details.sub_domain : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>App Name</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.app_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Company Name</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.company_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Company Address</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.company_address : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Phone Number</b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.phone_number : ''}</td>
                                    </tr>
                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.hosting_details !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Hosting Provider</b></td>
                                        <td>{this.props.member_profile !== null ? (this.props.member_profile.data.hosting_details && this.props.member_profile.data.hosting_details.hosting_provider) : 'N/A'}</td>
                                    </tr>

                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.hosting_details !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Hosting Username</b></td>
                                        <td>{this.props.member_profile !== null ? (this.props.member_profile.data.hosting_details && this.props.member_profile.data.hosting_details.username) : 'N/A'}</td>
                                    </tr>

                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.hosting_details !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Hosting Password</b></td>
                                        <td>{this.props.member_profile !== null ? (this.props.member_profile.data.hosting_details && this.props.member_profile.data.hosting_details.password) : 'N/A'}</td>
                                    </tr>
                                    {/*<tr style={this.props.member_profile !== null && this.props.member_profile.data != null && this.props.member_profile.data.stripe_data != null &&this.props.member_profile.data.stripe_data.access_token != null ? {display: 'block'} : {display: 'none'}}>*/}
                                    {/*    <td><b>Stripe Access Token</b></td>*/}
                                    {/*    <td>*/}
                                    {/*        {this.props.member_profile !== null && this.props.member_profile.data.stripe_data != null ? JSON.stringify(this.props.member_profile.data.stripe_data.access_token) : ''}*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr style={this.props.member_profile !== null && this.props.member_profile.data != null && this.props.member_profile.data.stripe_data != null &&this.props.member_profile.data.stripe_data.refresh_token != null ? {display: 'block'} : {display: 'none'}}>*/}
                                    {/*    <td><b>Stripe Refresh Token</b></td>*/}
                                    {/*    <td>*/}
                                    {/*        {this.props.member_profile !== null && this.props.member_profile.data.stripe_data != null ? JSON.stringify(this.props.member_profile.data.stripe_data.refresh_token) : ''}*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr style={this.props.member_profile !== null && this.props.member_profile.data != null && this.props.member_profile.data.stripe_data != null &&this.props.member_profile.data.stripe_data.access_token != null ? {display: 'block'} : {display: 'none'}}>*/}
                                    {/*    <td><b>Stripe Access Token</b></td>*/}
                                    {/*    <td>*/}
                                    {/*        {this.props.member_profile !== null && this.props.member_profile.data.stripe_data != null ? JSON.stringify(this.props.member_profile.data.stripe_data.access_token) : ''}*/}
                                    {/*    </td>*/}
                                    {/*</tr>*/}
                                    {/*<tr style={(this.props.member_profile != null && this.props.member_profile.data.t_shirt_size !== undefined) ? {display: 'table-row'} : {display: 'none'}}>*/}
                                    {/*    <td><b>T-Shirt Size</b></td>*/}
                                    {/*    <td>{this.props.member_profile !== null ? (this.props.member_profile.data.t_shirt_size && this.props.member_profile.data.t_shirt_size) : 'N/A'}</td>*/}
                                    {/*</tr>*/}


                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.t_shirt_size !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>T-Shirt Size</b></td>
                                        <td>{(this.props.member_profile !== null && this.props.member_profile.data.t_shirt_size !== 'default') ? this.props.member_profile.data.t_shirt_size : 'N/A'}</td>
                                    </tr>

                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.jean_size !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Jean Size</b></td>
                                        <td>{(this.props.member_profile !== null && this.props.member_profile.data.jean_size !== 'default') ? this.props.member_profile.data.jean_size : 'N/A'}</td>
                                    </tr>

                                    <tr style={(this.props.member_profile != null && this.props.member_profile.data.shoe_size !== undefined) ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Shoe Size</b></td>
                                        <td>{(this.props.member_profile !== null && this.props.member_profile.data.shoe_size !== 'default') ? this.props.member_profile.data.shoe_size : 'N/A'}</td>
                                    </tr>

                                    <tr style={this.props.member_profile !== null
                                    && this.props.member_profile.data.birthday_day !== undefined
                                    && this.props.member_profile.data.birthday_month !== undefined ? {display: 'table-row'} : {display: 'none'}}>
                                        <td><b>Birthday </b></td>
                                        <td>{this.props.member_profile !== null ? this.props.member_profile.data.birthday_month : ''} {this.props.member_profile !== null ? this.props.member_profile.data.birthday_day : ''}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("member details popup is closed");
        let payload = {
            id: "mandrill_verification_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }

}


const mapStateToProps = (state) => {
    return {
        member_profile: state.member_profile,
        popup: state.popup
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(MemberDetailsPopup));