import React, {Component} from 'react';

import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Logger from "../../core/util/Logger";
import {userLogoutAction} from "../../actions/logout-action";
import AccountManagerHelp from "./AccountManagerHelp";

class LoggedUserMenuBar extends Component {

    constructor() {
        super();
        this.state = {logout: false};
    }

    componentDidUpdate() {
        if (this.state.logout !== undefined && this.state.logout) {
            Logger.info("user successfully logged out and redirecting to the login");
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <div className="container nav-wrap">
                <a className="navbar-brand">
                    <img style={{width: 130, marginBottom: 6}}
                         src="../images/easy_macro_planner_logo.png"
                         alt="MacroActive"/>
                </a>
                <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation"
                        className="navbar-toggler"><span className="navbar-toggler-icon"></span></button>
                <div id="navbarSupportedContent" className="collapse navbar-collapse">

                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.props.user.data.auth.name}
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {/*<Link style={this.props.user.data.user_type !== 'admin_user' ? {display: 'block'} : {display: 'none'}}*/}
                                {/*className="dropdown-item" to={"/profile-edit"}>Profile</Link>*/}

                                <Link
                                    style={this.props.user.data.user_type !== 'admin_user' ? {display: 'block'} : {display: 'none'}}
                                    to={"/domains/setup"} className="dropdown-item">DNS Setup</Link>

                                <a className="dropdown-item" onClick={this.onClickLogout}>Logout</a>
                            </div>
                        </li>
                    </ul>

                </div>
                {/*<AccountManagerHelp/>*/}
            </div>
        );
    }


    onClickLogout = () => {
        Logger.info("on click logout button");

        if (localStorage.getItem('user-token') != null) {
            Logger.info("clear the user token");
            localStorage.removeItem('user-token');
        }
        this.props.userSignOut();
        this.setState({logout: true});
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const actions = {
    userSignOut: userLogoutAction
};

export default withRouter(connect(mapStateToProps, actions)(LoggedUserMenuBar));
