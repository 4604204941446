import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {newMemberRegistrationReducer} from "./member-registration-reducer";
import {handleSpinnerLoadingViewReducer} from "./loader-spinner-reducer";
import {userAuthenticationReducer} from "./user-authentication-reducer";
import {getDnsDetailsReducer} from "./dns-details-reducer";
import {memberProfileUpdateReducer} from "./member-profile-update-reducer";
import {getDnsProviderDetailsByIdReducer} from "./get-dns-provider-details-byid-reducer";
import {handleAdminGoogleAuthUrlReducer} from "./get-admin-google-oauth-url-reducer";
import {allRegisteredMembersReducer} from "./get-all-registered-members-reducer";
import {memberProfileDetailsByIdReducer} from "./get-member-profile-details-by-id-reducer";
import {handlePopupDisplayReducer} from "./popup-display-handler-reducer";
import {checkDomainAvailabilityReducer} from "./check-domain-availability-reducer";
import {purchasingNewDomainReducer} from "./purchasing-new-domain-reducer";
import {addExistingDomainDetailsReducer} from "./add-existing-domain-details-reducer";
import {resendMandrillDomainVerificationReducer} from "./resend-mandrill-domain-verification-reducer";
import {reserveNewDomainReducer} from "./reserve-new-domain-reducer";
import {addMemberHostingDetailsReducer} from "./add-member-hosting-details-reducer";
import {askHelpFromTechPersonReducer} from "./ask-help-from-tech-person-reducer";
import {getStripeConnectUrlReducer} from "./get-stripe-connect-url-reducer";
import {completeStripeConnectReducer} from "./complete-stripe-connect-reducer";
import {getUserDashboardSettingsReducer} from "./get-user-dashboard-settings-reducer";
import {newMemberInvitationReducer} from "./send-member-invitation-reducer";
import {validateMemberRegistrationLinkHashReducer} from "./validate-member-registration-link-reducer";
import {getMemberDomainDetailsReducer} from "./get-member-domain-details-reducer";
import {checkUserForwardedMandrillEmailReducer} from "./check-user-forwarded-mandrill-verification-reducer";
import {userArchiveReducer} from "./user-archive-reducer";
import {shareDnsSetupInstructionsReducer} from "./share-dns-setup-intructions-reducer";
import {adminMemberPasswordResetReducer} from "./admin-member-password-reset-reducer";

export default combineReducers({
    form: formReducer,
    member: newMemberRegistrationReducer,
    loader_spinner: handleSpinnerLoadingViewReducer,
    user: userAuthenticationReducer,
    dns: getDnsDetailsReducer,
    profile: memberProfileUpdateReducer,
    dns_provider: getDnsProviderDetailsByIdReducer,
    admin_auth: handleAdminGoogleAuthUrlReducer,
    all_members: allRegisteredMembersReducer,
    member_profile: memberProfileDetailsByIdReducer,
    popup: handlePopupDisplayReducer,
    domain_availability: checkDomainAvailabilityReducer,
    reserve_domain: reserveNewDomainReducer,
    purchased_domain: purchasingNewDomainReducer,
    existing_domain: addExistingDomainDetailsReducer,
    mandrill_verification: resendMandrillDomainVerificationReducer,
    member_hosting: addMemberHostingDetailsReducer,
    ask_help: askHelpFromTechPersonReducer,
    stripe_url: getStripeConnectUrlReducer,
    stripe_details: completeStripeConnectReducer,
    user_dashboard: getUserDashboardSettingsReducer,
    member_invitation: newMemberInvitationReducer,
    member_invitation_link: validateMemberRegistrationLinkHashReducer,
    member_domain: getMemberDomainDetailsReducer,
    mandrill_verification_forwarded: checkUserForwardedMandrillEmailReducer,
    user_archive: userArchiveReducer,
    share_dns_instructions: shareDnsSetupInstructionsReducer,
    admin_reset_member_password: adminMemberPasswordResetReducer
});
