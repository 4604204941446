import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const resendMandrillVerificationEmailByUser = (token, mailBoxId, displayLoader = false) => async dispatch => {
    Logger.info("resending mandrill domain verification email  to mailbox [" + mailBoxId + "]  with token [" + token + "]");

    if (displayLoader) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }

    //clear the redux store
    dispatch({type: "RESET_MANDRILL_VERIFICATION_STORE"});

    let formData = new FormData();
    formData.append("mailbox_id", mailBoxId);

    let response = await RestApiService.post("/mandrill/verification", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("mandrill verification email sent");
        dispatch({
            type: "RESEND_MANDRILL_VERIFICATION_SUCCESS",
            payload: {
                message: "Verification email has been sent, please forward it to verifications@easymacroplanner.com"
            }
        });
    } else {
        dispatch({
            type: "RESEND_MANDRILL_VERIFICATION_FAILED",
            payload: {
                message: "Error occurred while sending the email. Please try again shortly"
            }
        });
    }

    //loader need to be hidden, if the displayLoader is set yo true
    if (displayLoader) {
        //wait for 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
        Logger.info("hiding spinner")
    }
};