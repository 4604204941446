import React, {Component} from 'react';
import {connect} from "react-redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../../core/util/Logger";
import {checkDomainAvailabilityAction} from "../../../actions/user/check-domain-availability-action";
import {reserveNewDomainAction} from "../../../actions/user/user-reserve-new-domain-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";

let defaultStatus = {
    message_type: '',
    message: '',
    error_message: ''
};

class NewDomainSearch extends Component {

    constructor() {
        super();
        this.state = defaultStatus;
    }


    componentDidUpdate() {

        if (this.props.domain_availability != null &&
            this.state.message === '' &&
            this.props.domain_availability.data.message !== undefined) {
            if (this.props.domain_availability.success === true) {
                Logger.info("updating domain availability message");
                this.setState({message_type: 'notification', message: this.props.domain_availability.data.message});
            } else {
                this.setState({message_type: 'error', message: this.props.domain_availability.data.message});
            }
        }

        if (this.props.domain_availability != null &&
            this.state.error_message === '' &&
            this.props.domain_availability.success === false &&
            this.props.domain_availability.data.message !== undefined) {
            Logger.info("updating error message");
            this.setState({error_message: this.props.domain_availability.data.message});
        }
    }


    render() {
        return (
            <div style={this.props.display_form ? {display: 'block'} : {display: 'none'}} className={"search-name"}>
                <Formik
                    ref={this.formik}
                    initialValues={{
                        domainName: '',
                    }}
                    validationSchema={Yup.object().shape({
                        domainName: Yup.string()
                            .required('domain name is required')
                            .matches(/^[a-z]*\.?[a-z0-9][a-z0-9]*\.[a-z]+$/i,{message:input=>input.value+' is not a valid domain name'})
                            // .test('teet','invalid',value=>!value.contains("."))
                    })}
                    onSubmit={fields => {
                        this.handleDomainSearchSubmission(fields);
                    }}
                    render={({errors, status, touched}) => (
                        <div>
                            <div
                                style={(this.state.message_type === 'error') ? {display: 'block'} : {display: 'none'}}
                                className="alert alert-danger" role="alert">
                                {this.state.error_message}
                            </div>
                            <Form>
                                <div className="form align-items-center mt-3">
                                    <div className="row form-width">
                                        <div className="col-md-9 dns-search-section">
                                            {/*<label htmlFor="domainName">First Name</label>*/}
                                            <Field name="domainName" type="text"
                                                   style={{paddingRight: 'none !important'}}
                                                   onKeyUp={this.clearPreviousDomainSearch}
                                                   className={'form-control mb-2' + (errors.domainName && touched.domainName ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="domainName" component="div"
                                                          className="invalid-feedback"/>
                                            <div
                                                className={this.props.domain_availability != null
                                                && this.props.domain_availability.data.domain_available ? "valid-feedback" : "invalid-feedback"}
                                                style={this.props.domain_availability != null && this.props.domain_availability.success === true
                                                    ? {display: 'flex'} : {display: 'none'}}>
                                                {
                                                    this.state.message
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3 dns-search-section-button">
                                            <button type="submit" className="btn btn-primary search-btn">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </div>
                    )}
                />
                <div className="dns-footer">
                    <button type="submit"
                            onClick={this.onClickNextBtn.bind(this)}
                            className="btn btn-primary">Next
                    </button>
                </div>
            </div>
        );
    }


    handleDomainSearchSubmission = (data) => {
        Logger.info("handling the domain search submission ", data);
        this.props.searchDomainName(data.domainName, this.props.user.data.auth.token);
        // clear if there is any previous error
        this.setState(defaultStatus);
    };

    clearPreviousDomainSearch = () => {
        this.setState(defaultStatus);
        if (this.props.domain_availability != null) {
            Logger.info("resetting previous search result");
            this.props.resetReduxStore('RESET_DOMAIN_AVAILABILITY');
        }
    };


    onClickNextBtn() {
        Logger.info("on click next btn", this.props.domain_availability);
        if (this.props.domain_availability == null) {
            this.setState({message_type: 'error', error_message: 'Please select a domain name before moving forward!'});
        } else if (this.props.domain_availability.data.domain_available) {
            Logger.info("displaying the domain reservation form");
            this.props.parent_context.showDomainReservationView();
        } else {
            this.setState({message_type: 'error', error_message: 'Sorry! you cannot proceed with the domain !'});
        }
    }
}


const actions = {
    searchDomainName: checkDomainAvailabilityAction,
    reserveNewDomain: reserveNewDomainAction,
    resetReduxStore: resetReduxStoreAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        domain_availability: state.domain_availability,
        purchased_domain: state.purchased_domain,
        reserve_domain: state.reserve_domain
    }
};


export default connect(mapStateToProps, actions)(NewDomainSearch);
